import React from "react";

import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import axios from "axios";
import newCNSIcon from "../../click-n-ship/images/CNS_new.svg";
import featured_clicknship from "../../click-n-ship/images/featured_clicknship.svg";

class AppSelectSplashScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.toggleSpinner(false);
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid" style={{ marginBottom: "30%" }}>
          <div className="row mb-5 mt-5">
            <div className="col-12 text-center">
              <h2>
                Choose Your Click-N-Ship<sup>®</sup> Experience
              </h2>
            </div>
          </div>

          <div className="row d-flex justify-content-center align-items-center">
            <div
              className="col-sm-4"
              style={{
                border: "1px solid rgba(119, 119, 119, 1)",
                boxShadow: "2px 4px 8px 0px rgba(119, 119, 119, 1)",
                minWidth: "350px",
                paddingTop: "20px",
                paddingBottom: "90px",
                paddingLeft: "50px",
                paddingRight: "50px",
                maxHeight: "400px"
              }}
            >
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={newCNSIcon}
                  width="120px"
                  height="120px"
                  style={{ marginRight: "40px" }}
                />
              </div>
              <a
                href={this.props.lookupCodes.cnsb_landing_page}
                aria-label="Enhanced Click-N-Ship"
              >
                <h4
                  className="text-center"
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    textDecoration: "underline"
                  }}
                >
                  Enhanced Click-N-Ship<sup>®</sup>
                </h4>
              </a>

              <ul>
                <li style={{ paddingBottom: "10px", fontSize: "15px" }}>
                  Automatically save on shipping with lower Commercial Rates.
                </li>
                <li style={{ paddingBottom: "10px", fontSize: "15px" }}>
                  New features for business shippers: improved batch shipping
                  and label import.
                </li>
              </ul>
            </div>

            <div
              className="col-sm-4 ml-xl-5 ml-ml-0"
              style={{
                border: "1px solid rgba(119, 119, 119, 1)",
                boxShadow: "2px 4px 8px 0px rgba(119, 119, 119, 1)",
                minWidth: "350px",
                paddingTop: "50px",
                paddingBottom: "200px",
                paddingLeft: "50px",
                paddingRight: "50px",
                maxHeight: "400px",
                position: "relative"
              }}
            >
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={featured_clicknship}
                  width="80px"
                  height="80px"
                  className="text-center"
                />
              </div>

              <a href="/labelInformation" aria-label="Click-N-Ship">
                <h4
                  className="text-center"
                  style={{
                    marginTop: "25px",
                    marginBottom: "15px",
                    textDecoration: "underline"
                  }}
                >
                  Click-N-Ship<sup>®</sup>
                </h4>
              </a>

              <ul>
                <li style={{ paddingBottom: "10px", fontSize: "15px" }}>
                  Familiar interface and features.
                </li>
              </ul>
              <div
                style={{
                  backgroundColor: "#00000006",
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  padding: "15px",
                  fontSize: "14px"
                }}
              >
                <strong
                  style={{
                    fontSize: "15px",
                    color: "#333366",
                    width: "100%"
                  }}
                >
                  Important:
                </strong>{" "}
                This version of Click-N-Ship is going away, and you’ll be automatically upgraded to the new, enhanced Click-N-Ship experience.{" "}
                <a
                  href="https://faq.usps.com/s/article/Enhanced-Click-N-Ship-Experience-CNSv2?_gl=1*dxyjri*_gcl_au*MTM4NTIzMjI3NS4xNzExMTE3MjQw*_ga*MTkyMzYyOTc3Ny4xNzExMTE3MjQw*_ga_3NXP3C8S9V*MTcxMzQ2MDM2Ny44LjEuMTcxMzQ2MDYyNy4wLjAuMA..#LegacySunset"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "underline",
                    color: "#3366CC"
                  }}
                >
                  Find out what’s changing.
                </a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AppSelectSplashScreen;
